<template>
	<b-row>
		<b-col cols="12" md="3" class="mb-md-0 mb-2">
			<label>Тип запроса</label>
			<b-input-group style="flex-wrap: nowrap">
				<v-select
					:value="requestTypeFilter"
					placeholder="Выберите фильтр"
					:options="requestOptions"
					class="w-100"
					:searchable="false"
					:reduce="val => val.value"
					:clearable="false"
					@input="
						val => {
							$emit('update:requestTypeFilter', val)
						}
					"
				/>
				<b-input-group-append is-text v-if="requestTypeFilter">
					<feather-icon
						icon="XIcon"
						class="cursor-pointer"
						@click="
							val => {
								$emit('update:requestTypeFilter', '')
							}
						"
					/>
				</b-input-group-append>
			</b-input-group>
		</b-col>
		<b-col cols="12" md="3" class="mb-md-0 mb-2">
			<label>Тип ошибки</label>
			<b-input-group style="flex-wrap: nowrap">
				<v-select
					:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
					:value="roleFilter"
					placeholder="Выберите фильтр"
					:options="dependentFilterOptions"
					class="w-100"
					:searchable="false"
					:reduce="val => val.value"
					:clearable="false"
					@input="
						val => {
							$emit('update:roleFilter', val)
							$emit('update:statusFilter', '')
						}
					"
				/>
				<b-input-group-append is-text v-if="roleFilter">
					<feather-icon
						icon="XIcon"
						class="cursor-pointer"
						@click="
							val => {
								$emit('update:roleFilter', '')
								$emit('update:statusFilter', '')
							}
						"
					/>
				</b-input-group-append>
			</b-input-group>
		</b-col>
		<b-col cols="12" md="2" class="mb-md-0 mb-2">
			<label>Значение ошибки</label>
			<b-input-group style="flex-wrap: nowrap">
				<v-select
					:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
					:value="statusFilter"
					placeholder="Выберите статус"
					:searchable="false"
					:options="dependentStatusOptions"
					class="w-100"
					:clearable="false"
					:reduce="val => val.value"
					@input="val => $emit('update:statusFilter', val)"
				/>
				<b-input-group-append is-text v-if="statusFilter">
					<feather-icon
						icon="XIcon"
						class="cursor-pointer"
						@click="
							val => {
								$emit('update:roleFilter', '')
								$emit('update:statusFilter', '')
							}
						"
					/>
				</b-input-group-append>
			</b-input-group>
		</b-col>
		<b-col cols="12" md="3" class="mb-md-0 mb-2">
			<label>Поиск по документу</label>
			<b-input-group>
				<b-form-input
					:value="passportFilter"
					placeholder="Номер документа"
					@input="val => $emit('update:passportFilter', val)"
				/>
				<b-input-group-append v-if="passportFilter">
					<b-button
						class="btn-icon"
						variant="outline-secondary"
						style="opacity: 0.4"
						@click="
							val => {
								$emit('update:passportFilter', '')
								refetchData(true)
							}
						"
					>
						<feather-icon icon="XIcon" size="16" />
					</b-button>
				</b-input-group-append>
				<b-input-group-append v-if="passportFilter">
					<b-button class="btn-icon" variant="primary" @click="refetchData(true)">
						<feather-icon icon="SearchIcon" size="16" />
					</b-button>
				</b-input-group-append>
			</b-input-group>
		</b-col>
	</b-row>
</template>

<script>
	import {
		BCard,
		BCardHeader,
		BCardBody,
		BRow,
		BCol,
		BButton,
		BFormInput,
		BInputGroup,
		BInputGroupAppend,
	} from 'bootstrap-vue'
	import vSelect from 'vue-select'

	export default {
		components: {
			BRow,
			BCol,
			BCard,
			BCardHeader,
			BCardBody,
			vSelect,
			BButton,
			BFormInput,
			BInputGroup,
			BInputGroupAppend,
		},
		data() {
			return {
				filterOptions: [
					{ label: 'Наличие лица', value: 'hasFace' },
					{ label: 'Цветность', value: 'isColourful' },
					{ label: 'Размытость', value: 'blurriness' },
					{ label: 'Количество лиц', value: 'recognizedFaces' },
					{ label: 'Глаза', value: 'eyesOpen' },
					{ label: 'Поворот', value: 'faceAlignment' },
					{ label: 'Liveness', value: 'isAlive' },
					{ label: 'Comparison', value: 'isRecognized' },
					{ label: 'Combo', value: 'combo' },
					{ label: 'Внутренняя ошибка', value: 'errorInternal' },
				],
			}
		},
		computed: {
			dependentStatusOptions() {
				if (this.roleFilter == 'isAlive') return this.statusOptions
				if (this.roleFilter == 'combo') return this.statusOptions
				if (this.roleFilter == 'errorInternal' || this.roleFilter == 'hasFace')
					return [
						{ label: 'Есть', value: 'ok' },
						{ label: 'Нет', value: 'nok' },
					]
				return this.statusOptions.slice(0, 3)
			},
			dependentFilterOptions() {
				if (this.requestTypeFilter == 'comparison_only')
					return [
						{ label: 'Наличие лица', value: 'hasFace' },
						{ label: 'Comparison', value: 'isRecognized' },
						{ label: 'Внутренняя ошибка', value: 'errorInternal' },
					]
				return this.filterOptions
			},
		},
		props: {
			roleFilter: {
				type: [String, null],
				default: null,
			},
			planFilter: {
				type: [String, null],
				default: null,
			},
			statusFilter: {
				type: [String, null],
				default: null,
			},
			passportFilter: {
				type: [String, null],
				default: null,
			},
			requestTypeFilter: {
				type: [String, null],
				default: null,
			},
			planOptions: {
				type: Array,
				required: true,
			},
			statusOptions: {
				type: Array,
				required: true,
			},
			requestOptions: {
				type: Array,
				required: true,
			},
			refetchData: {
				type: Function,
			},
		},
	}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
